import { Trans } from "@lingui/react";
import { resendLegalGuardianVerification } from "../../api/user";
import { useAuth } from "../../hooks/use-auth";
import { Country } from "../../types/Country";
import { Button } from "../Button/Button";
import { ContentLayout } from "../ContentLayout/ContentLayout";
import styles from "./DoubleOptInPendingPage.module.scss";
import { i18n } from "@lingui/core";
import { useState } from "react";
import { InfoBox } from "../InfoBox/InfoBox";
import { SupportedLanguage } from "../IntlHandler/IntlHelper";

export const DoubleOptInPendingPage = () => {
  const auth = useAuth();
  const [isSent, setIsSent] = useState<boolean>(false);

  const sendDoubleOptInEmail = async () => {
    try {
      await resendLegalGuardianVerification(
        auth.user?.email,
        auth.user?.metadata?.guardian?.email,
        auth.user?.language as SupportedLanguage
      );
      setIsSent(true);
    } catch (err) {}
  };

  return (
    <ContentLayout>
      <div className={styles.container}>
        <div className={styles.container__sub}>
          <h1>{i18n._({ id: "double-opt-in-pending-page.title" })}</h1>
          {isSent && (
            <InfoBox
              message={i18n._({
                id: "double-opt-in-pending-page.inform-message-success",
              })}
              type={"info"}
            />
          )}
          <p>{i18n._({ id: "double-opt-in-pending-page.description1" })}</p>
          {auth.user?.country === Country.DE && (
            <>
              <p>
                <Trans
                  id="double-opt-in-pending-page.description2"
                  values={{
                    email: (
                      <a
                        target="_blank"
                        href="mailto:classpad.academy@casio.de"
                        rel="noreferrer"
                        style={{ color: "#3957a8" }}
                      >
                        <b>classpad.academy@casio.de</b>
                      </a>
                    ),
                  }}
                />
              </p>
              <p>{i18n._({ id: "double-opt-in-pending-page.description3" })}</p>
            </>
          )}
          {!isSent && (
            <Button
              label={i18n._({ id: "double-opt-in-pending-page.send-button" })}
              onClick={sendDoubleOptInEmail}
            />
          )}
        </div>
      </div>
    </ContentLayout>
  );
};
