import { SelfServiceRegistrationFlow } from "@ory/kratos-client";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Role } from "../types/Role";
import { User, userFromPlain, PlainUser, ExternalGroup } from "../types/User";
import { Operation } from "fast-json-patch";
import { Country, Language, defaultLanguageForCountry } from "../types/Country";
import { getCsrfToken } from "../components/Register/RegisterHelper";
import { Product } from "../types/Product";
import { GetUsersWithPagination } from "../components/UserManagement/UserManagement";
import { IdProvider } from "../types/IdProvider";
import { buildSearchParams } from "../utils/utils";
import { SchoolType } from "../components/AddSchool/SchoolTypes";
import { School } from "./school";
import { SupportedLanguage } from "../components/IntlHandler/IntlHelper";
import { TeacherCreateUserResult } from "../components/StudentList/AddStudentModal/AddStudentModal";
import {
  SubmitUserResponse,
  UserCsvImport,
} from "../components/StudentList/UploadStudentUser";

const url = "internal/v2/users";
const urlV1 = "internal/v1/users";

interface RegistrationInstructorMetadata {
  subjects: string[];
  school: {
    studentCount: number;
  };
}

interface RegistrationLearnerMetadata {
  guardian: {
    firstname: string;
    lastname: string;
    email: string;
  };
}

export interface RegistrationUser {
  email: string;
  password: string;
  type: Role;
  nickname?: string;
  firstname: string;
  lastname: string;
  country: Country;
  schoolId?: string;
  metadata?: RegistrationInstructorMetadata | RegistrationLearnerMetadata;
}

interface RegisterUserParams {
  flow: SelfServiceRegistrationFlow;
  user: RegistrationUser;
  apiBaseUrl?: string;
}

interface CaptureLicenseUsageData {
  userId?: string;
  externalId?: string;
  product: Product;
  accessDate: Date;
}

export enum UserStatus {
  ACTIVATED = "ACTIVATED",
  NONACTIVATED = "NONACTIVATED",
}

export enum SchoolLicenseStatus {
  EXPIRED = "EXPIRED",
  VALID = "VALID",
  NONEXISTING = "NONEXISTING",
}
export interface SearchUserCondition {
  country?: { value: Country; label: string; data: Country }[];
  idProvider?: { value: IdProvider; label: string; data: IdProvider }[];
  role?: {
    value: Role;
    label: string;
    data: Role;
  }[];
  userId?: string;
  firstName?: string;
  lastName?: string;
  externalId?: string;
  nickname?: string;
  email?: string;
  parentFirstName?: string;
  parentLastName?: string;
  parentEmail?: string;
  createdDateFrom?: string;
  createdTo?: string;
  status?: {
    value: UserStatus;
    label: string;
    data: UserStatus;
  }[];
  lastLoginFrom?: string;
  lastLoginTo?: string;
  schoolId?: string;
  tisMarketingConsent?: {
    value: "true" | "false";
    label: string;
    data: string;
  }[];
  tisProfilingConsent?: {
    value: "true" | "false";
    label: string;
    data: string;
  }[];
  teacherSchoolLicenseCPL?: {
    value: SchoolLicenseStatus;
    label: string;
    data: SchoolLicenseStatus;
  }[];
  teacherSchoolLicenseCPP?: {
    value: SchoolLicenseStatus;
    label: string;
    data: SchoolLicenseStatus;
  }[];
  individualLicenseCPL?: {
    value: SchoolLicenseStatus;
    label: string;
    data: SchoolLicenseStatus;
  }[];
  individualLicenseCPP?: {
    value: SchoolLicenseStatus;
    label: string;
    data: SchoolLicenseStatus;
  }[];
  trialLicense?: { value: "true" | "false"; label: string; data: boolean }[];
  studentSchoolLicenseCPL?: {
    value: SchoolLicenseStatus;
    label: string;
    data: SchoolLicenseStatus;
  }[];
  studentSchoolLicenseCPP?: {
    value: SchoolLicenseStatus;
    label: string;
    data: SchoolLicenseStatus;
  }[];
}

export interface SearchUsersData {
  country?: Country[];
  idProvider?: IdProvider[];
  role?: Role[];
  userId?: string;
  firstName?: string;
  lastName?: string;
  externalId?: string;
  nickname?: string;
  email?: string;
  parentFirstName?: string;
  parentLastName?: string;
  parentEmail?: string;
  createdDateFrom?: string;
  createdTo?: string;
  status?: UserStatus[];
  lastLoginFrom?: string;
  lastLoginTo?: string;
  schoolId?: string;
  tisMarketingConsent?: string[];
  tisProfilingConsent?: string[];
  teacherSchoolLicenseCPL?: SchoolLicenseStatus[];
  teacherSchoolLicenseCPP?: SchoolLicenseStatus[];
  individualLicenseCPL?: SchoolLicenseStatus[];
  individualLicenseCPP?: SchoolLicenseStatus[];
  trialLicense?: boolean[];
  studentSchoolLicenseCPL?: SchoolLicenseStatus[];
  studentSchoolLicenseCPP?: SchoolLicenseStatus[];
}

export interface UserWithStatus {
  id: string;
  nickname: string;
  country: Country;
  language: Language;
  type: Role;
  idProvider: IdProvider;
  daysSinceRegistration: number;
  tisMarketingConsent: boolean;
  tisProfilingConsent: boolean;
  licensesFromTrial: string[];
  firstname?: string;
  lastname?: string;
  cplTestPeriodEndDate?: Date;
  metadata?: {
    isPilotAccount?: boolean;
    guardian?: {
      email: string;
      firstname: string;
      lastname: string;
    };
    tisMarketingConsent?: boolean;
    tisProfilingConsent?: boolean;
  };
  schoolId?: string;
  subjects?: string[];
  studentCount?: number;
  salutation?: string;
  email?: string;
  teachingLevel?: SchoolType[];
  verified?: boolean;
  garGroups?: ExternalGroup[];
  externalId?: string;
  createdAt?: string;
  lastLoginAt?: string;
  school?: School;
  isActivated?: boolean;
  isActivatedByAdmin?: boolean;
}

export const registerUser = async ({
  flow,
  user,
  apiBaseUrl = "/app",
}: RegisterUserParams): Promise<void> => {
  const url = `${apiBaseUrl}/register`;
  const csrfToken = getCsrfToken(flow);
  const payload = {
    flow: flow.id,
    csrf: csrfToken,
    user: {
      ...user,
      language: defaultLanguageForCountry(user.country),
    },
  };
  return axios.post(url, payload);
};

interface GetUserParams {
  userId: string;
  apiBaseUrl?: string;
}

export const getUser = async ({
  userId,
  apiBaseUrl = "/app/api",
}: GetUserParams): Promise<User> => {
  const response = await axios.get<PlainUser>(`${apiBaseUrl}/${url}/${userId}`);
  console.log("plainUser", response);
  return userFromPlain(response.data);
};

interface UpdateUserParams {
  userId: string;
  patch: Operation[];
  apiBaseUrl?: string;
}

export const updateUser = async ({
  userId,
  patch,
  apiBaseUrl = "/app/api",
}: UpdateUserParams): Promise<User> => {
  try {
    const response = await axios.patch<PlainUser>(
      `${apiBaseUrl}/${url}/${userId}`,
      patch
    );
    return userFromPlain(response.data);
  } catch (err) {
    throw new Error("user.profile.save.error");
  }
};

export const deleteUser = async (apiBaseUrl = "/app/api"): Promise<void> => {
  try {
    await axios.delete<void>(`${apiBaseUrl}/v1/user`);
  } catch (err) {
    throw new Error("user.profile.delete.error");
  }
};

export const sendRecoveryEmail = async (
  apiBaseUrl = "/app/api",
  email: string
) => {
  try {
    const response = await axios.post<void>(
      `${apiBaseUrl}/v1/user/send-recovery-email`,
      null,
      {
        params: { email },
      }
    );
    return response;
  } catch (err) {
    throw new Error();
  }
};

export interface AdminDeleteUser {
  userId: string;
  idProvider: IdProvider;
}

export interface AdminDeleteUserResponse {
  success: {
    number: number;
    data: {
      userId: string;
      isDeleted: boolean;
    }[];
  };
  fail: {
    number: number;
    data: {
      userId: string;
      isDeleted: false;
    }[];
  };
}

export const adminDeleteUser = async ({
  deleteData,
  apiBaseUrl = "/app/api",
}: {
  deleteData:
    | {
        requestedBy: string;
        note: string;
        data: AdminDeleteUser[];
      }
    | undefined;
  apiBaseUrl?: string;
}): Promise<AdminDeleteUserResponse | undefined> => {
  try {
    if (deleteData?.data.length === 0) {
      return;
    }

    // This api call to proxy route in app server -> api.
    const response = await axios.post<AdminDeleteUserResponse>(
      `${apiBaseUrl}/${urlV1}/admin/delete`,
      { data: deleteData }
    );

    return response.data;
  } catch (err) {
    throw new Error("admin.user.profile.delete.error");
  }
};

export interface AdminUpdateUserPayload {
  email?: string;
  firstName?: string;
  lastName?: string;
  nickName?: string;
  parentFirstName?: string;
  parentLastName?: string;
  parentEmail?: string;
  schoolId?: string | null;
  numberOfStudents?: number;
  teachingLevel?: string[];
  salutation?: string;
  subjects?: string[];
}
export const adminUpdateUser = async ({
  userId,
  payload,
  apiBaseUrl = "/app/api",
}: {
  userId: string;
  payload: AdminUpdateUserPayload;
  apiBaseUrl?: string;
}): Promise<void> => {
  if (!userId) {
    return;
  }
  // This api call directly to api
  console.log("payload", payload);
  await axios.post<any>(`${apiBaseUrl}/${url}/${userId}/admin/update`, payload);
};

export interface AdminActivateUserResponse {
  id: string;
  email: string;
  isActivated: boolean;
  isActivatedByAdmin: boolean;
}

export const adminActivateUsers = async ({
  userIds,
  requestedBy,
  note,
  apiBaseUrl = "/app/api",
}: {
  userIds: string[] | undefined;
  requestedBy: string | undefined;
  note: string | undefined;
  apiBaseUrl?: string;
}): Promise<AdminActivateUserResponse[] | undefined> => {
  if (!userIds) {
    return;
  }

  const response = await axios.post<AdminActivateUserResponse[]>(
    `${apiBaseUrl}/${url}/activate`,
    {
      userIds,
      requestedBy,
      note,
    }
  );

  return response.data;
};

interface CheckUsersParams {
  userIds: string[];
}

export interface UserExisting {
  id: string;
  nickname?: string;
  role?: Role;
  exists: boolean;
}

export const checkUsers = async (
  usersToCheck: CheckUsersParams,
  apiBaseUrl = "/app/api"
): Promise<UserExisting[]> => {
  try {
    const response = await axios.post<UserExisting[]>(
      `${apiBaseUrl}/${url}/exist`,
      usersToCheck
    );
    return response.data;
  } catch (err) {
    throw new Error("user.check.error");
  }
};

export const activateCplTestPeriod = async (
  userId: string,
  apiBaseUrl = "/app/api"
) => {
  try {
    await axios.post(`${apiBaseUrl}/${url}/${userId}/cpl-test-period`);
  } catch (err) {
    throw new Error("cpl-test-period.activate.error");
  }
};

interface ValidateInstructorCodeParams {
  code: string;
  apiBaseUrl?: string;
}

interface ValidateInstructorCodeResponse {
  verified: boolean;
}

export const validateInstructorCode = async ({
  code,
  apiBaseUrl = "/app/api",
}: ValidateInstructorCodeParams): Promise<boolean> => {
  try {
    const response = await axios.post<ValidateInstructorCodeResponse>(
      `${apiBaseUrl}/${url}/verifyInstructorCode`,
      { code: code }
    );
    return response.data.verified;
  } catch (err) {
    throw new Error("instructor.code.validation.error");
  }
};

export const checkUserIsInTrialClass = async (
  userId: string,
  apiBaseUrl = "/app/api"
) => {
  try {
    const response = await axios.post<{
      isInTrialClass: boolean;
      cplTestPeriodEndDate: string;
    }>(`${apiBaseUrl}/${url}/${userId}/check-user-in-trial-class`);

    return response.data;
  } catch (err) {
    throw new Error(`user.check.user.is.in.trial.class.error ${err}`);
  }
};

export const captureLicenseUsage = async (
  userId: string | undefined,
  data: CaptureLicenseUsageData,
  apiBaseUrl = "/app/api"
): Promise<void> => {
  try {
    await axios.post<void>(
      `${apiBaseUrl}/${url}/${userId}/capture-license-usage`,
      data
    );
  } catch (err) {
    throw new Error(`capture.license.usage.error: ${err}`);
  }
};

export const getAndSearchUsers = async (
  {
    page,
    limit,
    sortOrder,
    sortBy,
  }: {
    page: number;
    limit: number;
    sortOrder?: "asc" | "desc";
    sortBy?: string;
  },
  search: SearchUserCondition,
  apiBaseUrl = "/app/api"
): Promise<GetUsersWithPagination> => {
  try {
    console.log(
      `getAndSearchUsers called with page: ${page}, limit:${limit}, sortOrder:${sortOrder}, sortBy:${sortBy}`
    );
    if (!page) {
      throw new Error("page is required");
    }

    if (!limit) {
      throw new Error("limit is required");
    }

    let queryUrl = `${apiBaseUrl}/${url}/search?page=${page}&limit=${limit}`;

    if (sortOrder) {
      queryUrl += `&sortOrder=${sortOrder}`;
    }

    if (sortBy) {
      queryUrl += `&sortBy=${sortBy}`;
    }

    let searchCondition: SearchUsersData = {};

    if (search.country && search.country.length > 0) {
      searchCondition.country = search.country.map((item) => item.data);
    }

    if (search.idProvider && search.idProvider.length > 0) {
      searchCondition.idProvider = search.idProvider.map((item) => item.data);
    }

    if (search.role && search.role.length > 0) {
      searchCondition.role = search.role.map((item) => item.data);
    }

    if (search.userId) {
      searchCondition.userId = search.userId;
    }

    if (search.firstName) {
      searchCondition.firstName = search.firstName;
    }

    if (search.lastName) {
      searchCondition.lastName = search.lastName;
    }

    if (search.externalId) {
      searchCondition.externalId = search.externalId;
    }

    if (search.nickname) {
      searchCondition.nickname = search.nickname;
    }

    if (search.email) {
      searchCondition.email = search.email;
    }

    if (search.parentFirstName) {
      searchCondition.parentFirstName = search.parentFirstName;
    }

    if (search.parentLastName) {
      searchCondition.parentLastName = search.parentLastName;
    }

    if (search.parentEmail) {
      searchCondition.parentEmail = search.parentEmail;
    }

    if (search.createdDateFrom) {
      searchCondition.createdDateFrom = search.createdDateFrom;
    }

    if (search.createdTo) {
      searchCondition.createdTo = search.createdTo;
    }

    if (search.status && search.status.length > 0) {
      searchCondition.status = search.status.map((item) => item.data);
    }

    if (search.lastLoginFrom) {
      searchCondition.lastLoginFrom = search.lastLoginFrom;
    }

    if (search.lastLoginTo) {
      searchCondition.lastLoginTo = search.lastLoginTo;
    }

    if (search.schoolId) {
      searchCondition.schoolId = search.schoolId;
    }

    if (search.tisMarketingConsent && search.tisMarketingConsent.length > 0) {
      searchCondition.tisMarketingConsent = search.tisMarketingConsent.map(
        (item) => item.data
      );
    }

    if (search.tisProfilingConsent && search.tisProfilingConsent.length > 0) {
      searchCondition.tisProfilingConsent = search.tisProfilingConsent.map(
        (item) => item.data
      );
    }

    if (
      search.teacherSchoolLicenseCPL &&
      search.teacherSchoolLicenseCPL.length > 0
    ) {
      searchCondition.teacherSchoolLicenseCPL =
        search.teacherSchoolLicenseCPL.map((item) => item.data);
    }

    if (
      search.teacherSchoolLicenseCPP &&
      search.teacherSchoolLicenseCPP.length > 0
    ) {
      searchCondition.teacherSchoolLicenseCPP =
        search.teacherSchoolLicenseCPP.map((item) => item.data);
    }

    if (search.individualLicenseCPL && search.individualLicenseCPL.length > 0) {
      searchCondition.individualLicenseCPL = search.individualLicenseCPL.map(
        (item) => item.data
      );
    }

    if (search.individualLicenseCPP && search.individualLicenseCPP.length > 0) {
      searchCondition.individualLicenseCPP = search.individualLicenseCPP.map(
        (item) => item.data
      );
    }

    if (search.trialLicense && search.trialLicense.length > 0) {
      searchCondition.trialLicense = search.trialLicense.map(
        (item) => item.data
      );
    }

    if (
      search.studentSchoolLicenseCPL &&
      search.studentSchoolLicenseCPL.length > 0
    ) {
      searchCondition.studentSchoolLicenseCPL =
        search.studentSchoolLicenseCPL.map((item) => item.data);
    }

    if (
      search.studentSchoolLicenseCPP &&
      search.studentSchoolLicenseCPP.length > 0
    ) {
      searchCondition.studentSchoolLicenseCPP =
        search.studentSchoolLicenseCPP.map((item) => item.data);
    }

    const searchParams = buildSearchParams(searchCondition);
    const endpoint = queryUrl + searchParams;

    const users = await axios.get<GetUsersWithPagination>(endpoint);

    return users.data;
  } catch (err) {
    console.error(`Error in getAndSearchUsers:${err}`);

    throw new Error(`get.user.with.pagination.and.sort.error: ${err}`);
  }
};

export const adminGetUser = async ({
  userId,
  apiBaseUrl = "/app/api",
}: GetUserParams): Promise<User> => {
  // This api call to proxy route in app server -> api.
  const response = await axios.get<PlainUser>(
    `${apiBaseUrl}/${url}/${userId}/detail`
  );

  return userFromPlain(response.data);
};

export const recoverActivatedStatusOfUsers = async (
  apiBaseUrl = "/app/api"
): Promise<void> => {
  await axios.post<void>(`${apiBaseUrl}/${url}/recover-activated-status-users`);
};

export const teacherCreateUser = async (
  country: string,
  firstname: string,
  lastname: string,
  email: string,
  apiBaseUrl = "/app/api"
): Promise<TeacherCreateUserResult> => {
  return await axios
    .post(`${apiBaseUrl}/${url}/teacher/create-user`, {
      country,
      firstname,
      lastname,
      email,
    })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        throw error.message;
      }
    });
};

export const teacherCreateUserByList = async (
  country: string,
  data: UserCsvImport[],
  apiBaseUrl = "/app/api"
): Promise<TeacherCreateUserResult[]> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const timeout = setTimeout(() => {
    source.cancel();
    // Timeout Logic
  }, 5 * 60 * 1000);
  return await axios
    .post(
      `${apiBaseUrl}/${url}/teacher/create-user-by-list`,
      {
        country,
        data,
      },
      { cancelToken: source.token }
    )
    .then((response: AxiosResponse) => {
      clearTimeout(timeout);
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (error.code === "ECONNABORTED") {
        console.error("Request timed out!");
      }
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        throw error.message;
      }
    });
};

export const submitUsersCsv = async (
  file: any,
  language: Language,
  apiBaseUrl = "/app/api"
): Promise<SubmitUserResponse> => {
  const formData = new FormData();
  formData.append("files", file);
  return await axios
    .post(`${apiBaseUrl}/${url}/teacher/submit-users-csv`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept-Language": language,
      },
    })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        throw error.message;
      }
    });
};

export interface KeepTis {
  userId: string;
  keepTis: boolean;
}

export const adminUpdateKeepTIS = async (
  data: KeepTis[],
  apiBaseUrl = "/app/api"
): Promise<void> => {
  await axios.post<void>(`${apiBaseUrl}/${url}/keep-tis`, { data });
};

export const resendLegalGuardianVerification = async (
  userEmail: string | undefined,
  guardianEmail: string | undefined,
  language: SupportedLanguage,
  apiBaseUrl = "/app/api"
) => {
  return await axios
    .post<void>(`${apiBaseUrl}/${url}/double-opt-in/guardian/resend-email`, {
      userEmail,
      guardianEmail,
      language,
    })
    .catch(function (error) {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
};
// export const testSendTriggerDeletion = async (
//   apiBaseUrl = "/app/api"
// ): Promise<void> => {
//   await axios.post<void>(`${apiBaseUrl}/${url}/cp-net/send-trigger-deletion`);
// };
