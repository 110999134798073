import styles from "./ConfirmModal.module.scss";
import { i18n } from "@lingui/core";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button/Button";

export interface ConfirmModalProps {
  header: string;
  description: string;
  isShowing: boolean;
  isLoading?: boolean;
  onRejection: () => void;
  onDone: () => void;
  hint?: string;
  confirmation?: string;
}

export const ConfirmModal = ({
  header,
  description,
  isShowing,
  onRejection,
  onDone,
  hint,
  isLoading,
}: ConfirmModalProps) => {
  return (
    <Modal
      title={header}
      isShowing={isShowing}
      closeButton={false}
      className={styles.withdrawPurchaseModal}
      size="medium"
      isLoading={isLoading}
    >
      <div data-testid="withdrawPurchaseModal">
        <p>{description}</p>
        <p>{hint}</p>
        <div className={styles.withdrawPurchaseModal__buttons}>
          <Button
            className={styles.modal__group__button__button__agree}
            btnStyle="secondary"
            label={i18n._({
              id: "sidebar.links.redirect.accept.classpad_net.button",
            })}
            onClick={onDone}
          ></Button>
          <Button
            className={styles.modal__group__button__button__cancel}
            btnStyle="warning"
            label={i18n._({
              id: "sidebar.links.redirect.cancel.classpad_net.button",
            })}
            onClick={onRejection}
          ></Button>
        </div>
      </div>
    </Modal>
  );
};
