import { Card } from "../Card/Card";
import { UserCsvImport } from "./UploadStudentUser";
import styles from "./UploadStudentUserReview.module.scss";
import { PageHeader } from "../PageHeader/PageHeader";
import { Table } from "../Table/Table";
import { Column } from "react-data-grid";
import { Button } from "../Button/Button";
import { useState } from "react";
import { UploadStudentUserResult } from "./UploadStudentUserResult";
import { Role } from "../../types/Role";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import { useAuth } from "../../hooks/use-auth";
import { teacherCreateUserByList } from "../../api/user";
import { Spinner } from "../Spinner/Spinner";
import { i18n } from "@lingui/core";
import { TeacherCreateUserResult } from "./AddStudentModal/AddStudentModal";
import { useToasts } from "../../provider/toast-provider";
const exportToCSV = require("export-to-csv");

export const UploadStudentUserReview = ({
  errors,
  data,
  setVisible,
  setCreatedUser,
}: {
  errors: string[];
  data: UserCsvImport[];
  setVisible: (isShowing: boolean) => void;
  setCreatedUser: (isCreatedUser: boolean) => void;
}) => {
  const columns: readonly Column<UserCsvImport>[] = [
    {
      key: "row",
      name: i18n._({ id: "student_list.upload.review.screen.column.line" }),
      sortable: false,
    },
    {
      key: "firstname",
      name: i18n._({
        id: "student_list.upload.review.screen.column.firstname",
      }),
      sortable: false,
    },
    {
      key: "lastname",
      name: i18n._({ id: "student_list.upload.review.screen.column.lastname" }),
      sortable: false,
    },
    {
      key: "email",
      name: i18n._({ id: "student_list.upload.review.screen.column.email" }),
      sortable: false,
    },
  ];
  const auth = useAuth();
  const { addToast } = useToasts();
  const [isShowResultScreen, setIsShowResultScreen] = useState<boolean>(false);
  const [result, setResult] = useState<TeacherCreateUserResult[]>([]);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
  const csvConfig = exportToCSV.mkConfig({
    useKeysAsHeaders: true,
    fieldSeparator: ",",
    quoteCharacter: "",
    filename: "student_account",
  });

  const onConfirm = async () => {
    try {
      setIsShowSpinner(true);
      await teacherCreateUserByList(auth.user?.country as string, data).then(
        (response) => {
          setResult(response);
          setIsShowResultScreen(true);
          setTimeout(() => {
            const csv = exportToCSV.generateCsv(csvConfig)(response);
            exportToCSV.download(csvConfig)(csv);
          }, 2000);
          setCreatedUser(true);
        }
      );
    } catch (err) {
      addToast(err as string, "error");
    } finally {
      setIsShowSpinner(false);
    }
  };

  return (
    <RestrictedTo roles={[Role.INSTRUCTOR]} redirects={true}>
      {!isShowResultScreen && (
        <div className={styles.container}>
          <PageHeader
            title={i18n._({ id: "student_list.upload.review.screen.header" })}
          />
          {errors.length !== 0 && (
            <Card
              title={i18n._({ id: "student_list.upload.review.screen.remark" })}
            >
              <div className={styles.remark}>
                {errors.map((error: string) => (
                  <span>{error}</span>
                ))}
              </div>
            </Card>
          )}
          <Card
            title={i18n._({ id: "student_list.upload.review.screen.valid" })}
          >
            <div>
              <Table
                columns={columns}
                rows={data}
                onRowsChange={() => {}}
                sortColumns={[]}
              ></Table>
            </div>
          </Card>
          <div className={styles.btn}>
            <Button
              label={i18n._({
                id: "student_list.upload.review.screen.btn.cancel",
              })}
              btnStyle="secondary"
              onClick={() => {
                setVisible(false);
              }}
            ></Button>
            <Button
              label={i18n._({
                id: "student_list.upload.review.screen.btn.confirm",
              })}
              type="submit"
              disabled={data.length === 0}
              onClick={onConfirm}
            ></Button>
          </div>
        </div>
      )}
      {isShowResultScreen && (
        <UploadStudentUserResult data={result} setVisible={setVisible} />
      )}
      <Spinner
        type="fullContainer"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </RestrictedTo>
  );
};
