import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import React, { useState } from "react";
import {
  ArrowRight,
  BookOpen,
  Box,
  Folder,
  Gift,
  Info,
  Key,
  Link as LinkIcon,
  User,
} from "react-feather";
import { Link } from "react-router-dom";
import { ReactComponent as IservLogo } from "../../assets/images/svg/iserv-logo.svg";
import { ReactComponent as MilLogo } from "../../assets/images/svg/mathe-im-leben-logo.svg";
import { ReactComponent as MatheMarathonLogo } from "../../assets/images/svg/mm-icon-2023-original.svg";
import { useAppConfig } from "../../hooks/use-app-config";
import { useAuth } from "../../hooks/use-auth";
import { classList as cl } from "../../lib/helpers";
import { Country } from "../../types/Country";
import { Role } from "../../types/Role";
import { Badge } from "../Badge/Badge";
import { BlockContent } from "../BlockContent/BlockContent";
import { Card } from "../Card/Card";
import { Grid, GridItem } from "../Grid/Grid";
import { NewsBoxIFrame } from "../NewsBoxIFrame/NewsBoxIFrame";
import { PageHeader } from "../PageHeader/PageHeader";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import styles from "./NavigationPage.module.scss";
import { HashLink } from "react-router-hash-link";
import { IdProvider } from "../../types/IdProvider";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { captureLicenseUsage } from "../../api/user";
import { Product } from "../../types/Product";

export interface NavigationPageProps {
  remainingLearnerTestDays?: number;
  learnerTestPeriodActive?: boolean;
}

export const NavigationPage = ({
  remainingLearnerTestDays,
  learnerTestPeriodActive,
}: NavigationPageProps) => {
  const [isShowConfirmRedirect, setIsShowConfirmRedirect] =
    useState<boolean>(false);

  // const { classpadNetUrl, classpadLearningUrl, mesExcercisesLyceeLaunchUrl } =
  //   useAppConfig();
  const { classpadNetUrl, classpadLearningUrl } = useAppConfig();

  const auth = useAuth();

  const role = auth.userInfo?.role;

  const redirectToClassPadDotNet = async () => {
    /** Capture license usage here */
    window.open(classpadNetUrl, "_blank", "noreferrer");
    setIsShowConfirmRedirect(false);

    await captureLicenseUsage(auth.user?.id, {
      userId: auth.user?.id,
      externalId: auth.user?.externalUserId,
      product: Product.CLASSPAD_NET_PLUS,
      accessDate: new Date(),
    });
  };

  return (
    <div>
      <PageHeader
        title={i18n._({
          id: `navigation-page.title`,
        })}
      />
      <Grid>
        <GridItem width="2/3">
          <Grid>
            {window.featureToggles?.FT_MATHE_MARATHON_VISIBLE &&
              auth.user?.country === Country.DE && (
                <GridItem width="1/1" className={styles.navigationBoxGridItem}>
                  <Link to="/mathe-marathon">
                    <NavigationBox
                      title={i18n._({
                        id: `navigation-page.card.title.mathe-marathon.${role?.toLowerCase()}`,
                      })}
                      description={
                        <div className={styles.matheMarathonContent}>
                          <div
                            className={styles.matheMarathonContent__body}
                            dangerouslySetInnerHTML={{
                              __html: i18n._({
                                id: `navigation-page.card.description.mathe-marathon.${role?.toLowerCase()}`,
                              }),
                            }}
                          ></div>
                          <div className={styles.matheMarathonContent__footer}>
                            <span>
                              <i>
                                <ArrowRight />
                              </i>
                              <span>
                                {i18n._({
                                  id: `navigation-page.card.button-description.mathe-marathon.${role?.toLowerCase()}`,
                                })}
                              </span>
                            </span>
                            <div
                              className={
                                styles.matheMarathonContent__footer__logo
                              }
                            >
                              <span>
                                {i18n._({
                                  id: `navigation-page.card.logo-info.mathe-marathon`,
                                })}
                              </span>
                              <MilLogo />
                            </div>
                          </div>
                        </div>
                      }
                      variant="matheMarathon"
                    />
                  </Link>
                </GridItem>
              )}
            <RestrictedTo roles={[Role.INSTRUCTOR]}>
              <GridItem
                width={"1/1"}
                className={styles.navigationBoxGridItem}
                hidden={auth.user?.country === Country.FR}
              >
                <HashLink to="/profile#tis">
                  <NavigationBox
                    title={i18n._({
                      id: `teacher_info_service`,
                    })}
                    description={i18n._({
                      id: `navigation-page.card.description.teacher-info-service`,
                    })}
                    icon={<Info />}
                  />
                </HashLink>
              </GridItem>
            </RestrictedTo>
            <GridItem
              width={"1/1"}
              className={styles.navigationBoxGridItem}
              hidden={auth.user?.country === Country.FR}
            >
              {/* <HashLink to="/profile#tis"> */}
              <Link to="/contexts">
                <NavigationBox
                  title={i18n._({
                    id: `navigation-page.card.title.contexts`,
                  })}
                  description="Hier kannst du virtuelle Klassenräume beitreten oder sie als Lehrkraft erstellen."
                  icon={<Folder />}
                />
              </Link>
            </GridItem>

            <GridItem width="1/2" className={styles.navigationBoxGridItem}>
              <a
                href={classpadLearningUrl}
                target="_blank"
                rel="noreferrer"
                onClick={async () => {
                  /** Capture license usage here */
                  await captureLicenseUsage(auth.user?.id, {
                    userId: auth.user?.id,
                    externalId: auth.user?.externalUserId,
                    product: Product.CLASSPAD_LEARNING,
                    accessDate: new Date(),
                  });
                }}
              >
                <NavigationBox
                  title={i18n._({
                    id: `navigation-page.card.title.kikora`,
                  })}
                  description={i18n._({
                    id: `navigation-page.card.description.kikora`,
                  })}
                  icon={<BookOpen />}
                  variant={
                    learnerTestPeriodActive ? "cplTestPeriod" : "default"
                  }
                  remainingLearnerTestDays={remainingLearnerTestDays}
                />
              </a>
            </GridItem>
            {/*-------------------------------- Moodle link ------------------------------------------------ */}
            {/* <GridItem width="1/2" className={styles.navigationBoxGridItem}>
              <a
                href="https://anhhnm.moodlecloud.com/login/index.php"
                target="_blank"
                rel="noreferrer"
              >
                <NavigationBox
                  // title={i18n._({
                  //   id: `navigation-page.card.title.kikora`,
                  // })}
                  title="Moodle Cloud"
                  description="description test"
                  icon={<BookOpen />}
                />
              </a>
            </GridItem> */}

            {/*-------------------------------- Moodle link ------------------------------------------------ */}

            {auth.user?.idProvider !== IdProvider.GAR && (
              <GridItem width="1/2" className={styles.navigationBoxGridItem}>
                <div onClick={() => setIsShowConfirmRedirect(true)}>
                  <NavigationBox
                    title={i18n._({
                      id: `navigation-page.card.title.classpad_net`,
                    })}
                    description={i18n._({
                      id: `navigation-page.card.description.classpad_net`,
                    })}
                    icon={<Box />}
                  />
                </div>
              </GridItem>
            )}

            <RestrictedTo roles={[Role.LEARNER, Role.INSTRUCTOR]}>
              <GridItem
                width="1/2"
                hidden={
                  auth.user?.country === Country.FR &&
                  !window.featureToggles?.FT_ENABLED_FOR_FRANCE
                }
                className={styles.navigationBoxGridItem}
              >
                <Link to="/licenses">
                  <NavigationBox
                    title={i18n._({
                      id: `navigation-page.card.title.licenses`,
                    })}
                    description={i18n._({
                      id: `navigation-page.card.description.licenses`,
                    })}
                    icon={<Key />}
                  />
                </Link>
              </GridItem>
            </RestrictedTo>

            <GridItem width="1/2" className={styles.navigationBoxGridItem}>
              <Link to="/profile">
                <NavigationBox
                  title={i18n._({
                    id: `navigation-page.card.title.profile`,
                  })}
                  description={i18n._({
                    id: `navigation-page.card.description.profile`,
                  })}
                  icon={<User />}
                />
              </Link>
            </GridItem>
            {/* {(auth.user?.idProvider === IdProvider.GAR ||
              (auth.user?.idProvider === IdProvider.CLASSPAD &&
                auth.user?.country === Country.FR)) && (
              <GridItem width="1" className={styles.navigationBoxGridItem}>
                <a
                  href={mesExcercisesLyceeLaunchUrl}
                  target="_blank"
                  rel="noreferrer"
                  onClick={async () => {
                    await captureLicenseUsage(auth.user?.id, {
                      userId: auth.user?.id,
                      externalId: auth.user?.externalUserId,
                      product: Product.MES_EXERCISES_LYCEE,
                      accessDate: new Date(),
                    });
                  }}
                >
                  <NavigationBox
                    title="Mes exercices Lycée"
                    description={
                      <>
                        <p>
                          Entraînez-vous sur des exercices lycées avec les
                          calculatrices CASIO
                        </p>
                        <br />
                        <p>
                          Vous serez redirigé vers un site Web exploité par
                          Casio Computer Co. Ltd
                        </p>
                      </>
                    }
                    icon={<BookOpen />}
                  />
                </a>
              </GridItem>
            )} */}

            <GridItem width="1/1" className={styles.navigationBoxGridItem}>
              <Card
                title={i18n._({
                  id: `navigation-page.${role?.toLowerCase()}.headline`,
                })}
              >
                <BlockContent>
                  <div
                    className={styles.navigationPage__messageContainer}
                    dangerouslySetInnerHTML={{
                      __html: i18n._({
                        id: `navigation-page.${role?.toLowerCase()}.body`,
                      }),
                    }}
                  />
                </BlockContent>
              </Card>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem width="1/3">
          <div className={styles.navigationPageAside}>
            <div className={styles.navigationPageAside__header}>
              <h3>
                <Trans id="navigation-page.newsPostBox.headline" />
              </h3>
            </div>
            <NewsBoxIFrame />
          </div>
        </GridItem>
      </Grid>

      <ConfirmModal
        header={i18n._({
          id: `sidebar.links.redirect.title.classpad_net`,
        })}
        description={i18n._({
          id: "sidebar.links.redirect.description.classpad_net",
        })}
        onDone={redirectToClassPadDotNet}
        onRejection={() => setIsShowConfirmRedirect(false)}
        isShowing={isShowConfirmRedirect}
      ></ConfirmModal>
    </div>
  );
};

interface NavigationBoxProps {
  title: string;
  description: React.ReactNode;
  icon?: React.ReactNode;
  variant?: "default" | "matheMarathon" | "cplTestPeriod";
  remainingLearnerTestDays?: number;
  badgeText?: string;
}

const NavigationBox = ({
  title,
  description,
  icon,
  variant = "default",
  remainingLearnerTestDays,
  badgeText,
}: NavigationBoxProps) => {
  const auth = useAuth();
  return (
    <Card
      className={cl(styles.navigationBox, styles[`navigationBox_${variant}`])}
      variant={variant}
      clickable
    >
      <div className={styles.navigationBox__header}>
        <h3>{title}</h3>
        <i>
          {variant === "matheMarathon" ? <MatheMarathonLogo /> : <ArrowRight />}
        </i>
      </div>
      <div className={styles.navigationBox__content}>{description}</div>
      <div className={styles[`navigationBox__${variant}__footer`]}>
        {icon && <i>{icon}</i>}
        {auth.userInfo?.externalGroups &&
          auth.userInfo?.externalGroups.length > 0 &&
          badgeText && (
            <Badge
              icon={
                <>
                  <LinkIcon />
                  <IservLogo />
                </>
              }
              type="secondary"
              size="small"
              label={badgeText}
            ></Badge>
          )}
        {variant === "cplTestPeriod" && (
          <Badge
            className={styles.navigationBox__cplTestPeriod__footer__badge}
            size="small"
            type="cplTestPeriod"
            label={i18n._({
              id:
                remainingLearnerTestDays === 1
                  ? "navigation-page.card.cplTestPeriod.1day"
                  : "navigation-page.card.cplTestPeriod",
              values: { days: remainingLearnerTestDays },
            })}
            icon={<Gift />}
          />
        )}
      </div>
    </Card>
  );
};
