import { useEffect, useState } from "react";

import { Modal } from "../../Modal/Modal";
import { Grid, GridItem } from "../../Grid/Grid";
import styles from "./UserDetail.module.scss";

import { Formik, Form } from "formik";
import { TextInput } from "../../TextInput/TextInput";
import { Table } from "../../Table/Table";
import { Column } from "react-data-grid";
import { IdProvider } from "../../../types/IdProvider";
import { Button } from "../../Button/Button";

import { adminGetUser, adminUpdateUser } from "../../../api/user";
import { User } from "../../../types/User";
import {
  convertStatusToText,
  formatUTCDateToTimePartAndDatePart,
  getTeachingLevelSelectValue,
} from "../../../utils/utils";
import { adminGetUserContexts } from "../../../api/user-contexts";
import { Role } from "../../../types/Role";
import { Spinner } from "../../Spinner/Spinner";
import { useToasts } from "../../../provider/toast-provider";
import { SchoolSelect } from "../../SchoolSelect/SchoolSelect";
import { Country } from "../../../types/Country";
import { querySchools } from "../../../api/school";
import { validEmailFormat } from "../../../utils/emailValidation";
import { SendRecoveryEmailModal } from "../SendRecoveryEmailModal/SendRecoveryEmailModal";
import { sendRecoveryEmail } from "../../../api/user";
import { writeSendRecoveryEmailHistory } from "../../../api/user-management-history";
import { SendRecoveryEmailSuccessModal } from "../SendRecoveryEmailModal/SendRecoveryEmailSuccessModal";
import { Select } from "../../SelectWithoutFormik/Select";
import { salutationOptions } from "../../Register/RegisterHelper";
import { SupportedLanguage } from "../../IntlHandler/IntlHelper";
import { TeachingLevelSelectAdmin } from "../../TeachingLevelSelect/TeachingLevelSelectAdmin/TeachingLevelSelectAdmin";
import { InstructorSubjectDataStepAdmin } from "../../DataStep/InstructorSubjectDataStepAdmin/InstructorSubjectDataStepAdmin";
import { LicenseDetail } from "./../LicenseDetail/LicenseDetail";
import {
  SchoolLicenseInLicenseDetail,
  getSchoolLicenseListByUser,
} from "../../../api/user-licenses";
import { Product } from "../../../types/Product";

const columns: readonly Column<Row>[] = [
  {
    key: "className",
    name: "Class name",
  },
  {
    key: "classId",
    name: "ClassID",
  },
  {
    key: "externalId",
    name: "ExternalID",
  },
  {
    key: "origin",
    name: "Origin",
  },
];

interface Row {
  className: string;
  classId: string;
  externalId: string | undefined;
  origin: IdProvider;
}

export interface EditUserParams {
  firstName?: string;
  lastName?: string;
  nickName?: string;
  schoolId?: any;
}
export interface EditStudentUserParams extends EditUserParams {
  parentFirstName?: string;
  parentLastName?: string;
  parentEmail?: string;
}

export interface EditTeacherUserParams extends EditUserParams {
  numberOfStudents?: number;
  teachingLevel?: string[];
  salutation?: string;
  subjects?: string[];
}

export interface SchoolLicense {
  id: string;
  recordId: string;
  schoolId: string;
  products: Product[];
  validUntil: Date;
  quantity: number;
  salesSource: string | null;
  reference: string | null;
}

interface UserDetailProps {
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
  selectedUserId: string;
  setIsShowActiveUserAccountModal: (isShowing: boolean) => void;
  setIsShowDeleteUserAccountModal: (isShowing: boolean) => void;
}

export const UserDetailModal: React.FC<UserDetailProps> = ({
  isShowing,
  setVisible,
  selectedUserId,
  setIsShowActiveUserAccountModal,
  setIsShowDeleteUserAccountModal,
}: UserDetailProps) => {
  const [rows, setRows] = useState<Row[]>([]);

  const [userDetail, setUserDetail] = useState<User>();
  const [schoolLicenseList, setSchoolLicenseList] = useState<
    SchoolLicenseInLicenseDetail[]
  >([]);

  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
  const [isShowSendRecoveryEmailModal, setIsShowSendRecoveryEmailModal] =
    useState<boolean>(false);
  const [
    isShowSendRecoveryEmailSuccessModal,
    setIsShowSendRecoveryEmailSuccessModal,
  ] = useState<boolean>(false);

  // const [errorMsgEmail, setErrorMsgEmail] = useState<string>();
  const [errorMsgFirstName, setErrorMsgFirstName] = useState<string>();
  const [errorMsgLastName, setErrorMsgLastName] = useState<string>();
  const [errorMsgNickName, setErrorMsgNickName] = useState<string>();
  const [errorMsgParentFirstName, setErrorMsgParentFirstName] =
    useState<string>();
  const [errorMsgParentLastName, setErrorMsgParentLastName] =
    useState<string>();
  const [errorMsgParentEmail, setErrorMsgParentEmail] = useState<string>();
  const [errorMsgNumberOfStudents, setErrorMsgNumberOfStudents] =
    useState<string>();
  const [errorMsgSchool, setErrorMsgSchool] = useState<string>();
  const [errorMsgTeachingLevel, setErrorMsgTeachingLevel] = useState<string>();
  const [errMsgSubject, setErrorMsgSubject] = useState<string>();

  const [isExternalUser, setIsExternalUser] = useState<boolean>(false);
  const [isDisableSaveButton, setIsDisableSaveButton] = useState<boolean>(true);
  const [isChangedProfile, setIsChangedProfile] = useState<boolean>(false);

  const { addToast } = useToasts();

  useEffect(() => {
    async function fetchAPI() {
      try {
        setIsShowSpinner(true);

        let user = await adminGetUser({ userId: selectedUserId });
        setUserDetail(user);

        const isExternalUser =
          user.idProvider !== IdProvider.CLASSPAD && !!user.externalUserId;
        setIsExternalUser(isExternalUser);

        const contexts = await adminGetUserContexts({
          userId: selectedUserId,
        });

        // Check if role is INSTRUCTOR -> get school license records

        if (user && user.type === Role.INSTRUCTOR) {
          const schoolLicenses = await getSchoolLicenseListByUser(user.id);
          setSchoolLicenseList(schoolLicenses);
        }

        let rows: Row[] = [];

        for (let context of contexts) {
          rows.push({
            className: context.name,
            classId: context.id,
            externalId: context.externalId,
            origin: context.origin,
          });
        }
        setRows(rows);
        setIsShowSpinner(false);
      } catch (err) {
        addToast("Error on fetch user detail data", "error");
        setIsShowSpinner(false);
      }
    }
    fetchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId]);

  const fetchUserDetail = async () => {
    let user = await adminGetUser({ userId: selectedUserId });
    setUserDetail(user);
  };

  async function onSendRecoveryEmail(email: string) {
    try {
      const response = await sendRecoveryEmail(undefined, email);
      if (response.status === 200) {
        setIsShowSendRecoveryEmailModal(false);
        setIsShowSendRecoveryEmailSuccessModal(true);
        await writeSendRecoveryEmailHistory(email, "SUCCESS");
      }
    } catch (e) {
      await writeSendRecoveryEmailHistory(email, "FAILED");
    }
  }
  const renderTisConsent = (value: boolean | undefined) => {
    if (value === true) {
      return "True";
    }
    if (value === false) {
      return "False";
    }
    if (value === undefined) {
      return "Null";
    }
  };

  useEffect(() => {
    if (
      isChangedProfile &&
      userDetail?.type === Role.LEARNER &&
      !errorMsgFirstName &&
      !errorMsgLastName &&
      !errorMsgNickName &&
      !errorMsgParentEmail &&
      !errorMsgParentFirstName &&
      !errorMsgParentLastName &&
      !errorMsgSchool
    ) {
      setIsDisableSaveButton(false);
    }

    if (
      isChangedProfile &&
      userDetail?.type === Role.INSTRUCTOR &&
      !errorMsgFirstName &&
      !errorMsgLastName &&
      !errorMsgNickName &&
      !errorMsgNumberOfStudents &&
      !errorMsgSchool &&
      !errorMsgTeachingLevel &&
      !errMsgSubject
    ) {
      setIsDisableSaveButton(false);
    }
  }, [
    userDetail,
    errorMsgFirstName,
    errorMsgLastName,
    errorMsgNickName,
    errorMsgParentFirstName,
    errorMsgParentLastName,
    errorMsgParentEmail,
    errorMsgNumberOfStudents,
    errorMsgSchool,
    errorMsgTeachingLevel,
    errMsgSubject,
    isChangedProfile,
  ]);

  return (
    <Modal
      title="User Detail"
      isShowing={isShowing}
      closeButton={true}
      size="superlarge"
      onHide={() => {
        setVisible(false);
        setUserDetail(undefined);
        setRows([]);
      }}
    >
      <div className={styles.userDetailContainer}>
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={{
            email: userDetail?.email || "",
            firstName: userDetail?.firstname || "",
            lastName: userDetail?.lastname || "",
            nickName: userDetail?.nickname || "",
            parentFirstName: userDetail?.metadata?.guardian?.firstname || "",
            parentLastName: userDetail?.metadata?.guardian?.lastname || "",
            parentEmail: userDetail?.metadata?.guardian?.email || "",
            numberOfStudents: userDetail?.metadata?.school?.studentCount || 0,
            tisMarketingConsent: renderTisConsent(
              userDetail?.metadata?.tisMarketingConsent
            ),
            tisProfilingConsent: renderTisConsent(
              userDetail?.metadata?.tisProfilingConsent
            ),
            salutation: userDetail?.metadata?.salutation || "",
            schools: userDetail?.school
              ? {
                  value: userDetail?.school?.id || "",
                  label: userDetail?.school?.name || "",
                  school: userDetail?.school,
                }
              : {
                  value: "",
                  label: "",
                  school: {
                    id: "",
                    name: "",
                    address: "",
                    addressAddition: "",
                    zip: "",
                    city: "",
                    addressCountry: "",
                    country: Country.DE,
                    type: "",
                    schoolId: "",
                    salesforceId: "",
                  },
                },
            subjects: userDetail?.subjects,

            teachingLevel: getTeachingLevelSelectValue(
              userDetail?.metadata?.teachingLevel || []
            ),
          }}
          validate={(values) => {
            setIsChangedProfile(true);
          }}
          onSubmit={async (values) => {
            try {
              let editUserParams:
                | EditStudentUserParams
                | EditTeacherUserParams = {};

              setIsShowSpinner(true);

              if (userDetail?.type === Role.LEARNER) {
                editUserParams = {
                  firstName: values.firstName && values.firstName.trim(),
                  lastName: values.lastName && values.lastName.trim(),
                  nickName: values.nickName && values.nickName.trim(),
                  parentFirstName:
                    values.parentFirstName && values.parentFirstName.trim(),
                  parentLastName:
                    values.parentLastName && values.parentLastName.trim(),
                  parentEmail: values.parentEmail && values.parentEmail.trim(),
                  schoolId: values.schools?.school.id || null,
                };
              }

              if (userDetail?.type === Role.INSTRUCTOR) {
                const mainParams = {
                  firstName: values.firstName && values.firstName.trim(),
                  lastName: values.lastName && values.lastName.trim(),
                  nickName: values.nickName && values.nickName.trim(),
                  schoolId: values.schools.school.id || null,
                  salutation: values.salutation,
                  subjects: values.subjects,
                };

                if (userDetail?.country === Country.DE) {
                  editUserParams = {
                    ...mainParams,
                    numberOfStudents: values.numberOfStudents,
                  };
                }

                if (userDetail?.country === Country.FR) {
                  editUserParams = {
                    ...mainParams,
                    teachingLevel: values?.teachingLevel?.map(
                      (item) => item.value
                    ),
                  };
                }
              }

              await adminUpdateUser({
                userId: userDetail?.id || "",
                payload: editUserParams,
              });

              addToast("Edit user successfully");
              setIsDisableSaveButton(true);
              setIsChangedProfile(false);
            } catch (e) {
              setIsShowSpinner(false);
              setIsDisableSaveButton(true);
              addToast(`Error on edit user detail data ${e}`, "error");
            } finally {
              setIsShowSpinner(false);
              setIsDisableSaveButton(true);
            }
          }}
        >
          {({ handleChange, values, setFieldValue }) => (
            <Form>
              <div className={styles.filterContainer}>
                <Grid>
                  <GridItem width="1/3">
                    <TextInput
                      id="country"
                      label="Country"
                      disable
                      value={userDetail?.country}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <TextInput
                      id="idProvider"
                      label="ID Provider"
                      disable
                      value={userDetail?.idProvider}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <TextInput
                      id="role"
                      label="Role"
                      disable
                      value={
                        userDetail?.type === Role.INSTRUCTOR
                          ? "Teacher"
                          : "Student"
                      }
                    />
                  </GridItem>
                </Grid>
              </div>

              <div className={styles.filterContainer}>
                <Grid>
                  <GridItem width="1/3">
                    <TextInput
                      id="userId"
                      label="UserID"
                      disable
                      value={userDetail?.id}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <TextInput
                      id="externalId"
                      label="ExternalID"
                      disable
                      value={userDetail?.externalUserId}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <TextInput
                      id="status"
                      label="Status"
                      disable
                      value={convertStatusToText(userDetail?.status)}
                    />
                  </GridItem>
                </Grid>
              </div>

              <div className={styles.filterContainer}>
                <Grid>
                  <GridItem width="1/3">
                    <TextInput
                      id="email"
                      label="Email"
                      onChange={handleChange}
                      value={values.email}
                      // errorMessage={errorMsgEmail}
                      disable
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <TextInput
                      id="createdDate"
                      label="Created Date"
                      disable
                      value={formatUTCDateToTimePartAndDatePart(
                        new Date(userDetail?.createdAt || "").toUTCString()
                      )}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <TextInput
                      id="lastLogin"
                      label="Last Login"
                      disable
                      value={formatUTCDateToTimePartAndDatePart(
                        new Date(userDetail?.lastLoginAt || "").toUTCString()
                      )}
                    />
                  </GridItem>
                </Grid>
              </div>

              <div className={styles.filterContainer}>
                <Grid>
                  <GridItem width="1/3">
                    <TextInput
                      id="firstName"
                      label="Firstname"
                      onChange={handleChange}
                      defaultValue={userDetail?.firstname}
                      value={values.firstName}
                      errorMessage={errorMsgFirstName}
                      onBlur={() => {
                        if (values.firstName?.trim().length === 0) {
                          setErrorMsgFirstName("Required field");
                          setIsDisableSaveButton(true);
                        }
                        if (
                          values.firstName &&
                          (values.firstName?.trim().length < 2 ||
                            values.firstName?.trim().length > 20)
                        ) {
                          setErrorMsgFirstName(
                            "Must be between 2 and 20 characters"
                          );
                          setIsDisableSaveButton(true);
                        }
                        if (
                          values.firstName?.trim().length >= 2 &&
                          values.firstName?.trim().length <= 20
                        ) {
                          setErrorMsgFirstName(undefined);
                        }
                      }}
                      disable={isExternalUser}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <TextInput
                      id="lastName"
                      label="Lastname"
                      onChange={handleChange}
                      defaultValue={userDetail?.lastname}
                      value={values.lastName}
                      errorMessage={errorMsgLastName}
                      onBlur={() => {
                        if (values.lastName?.trim().length === 0) {
                          setErrorMsgLastName("Required field");
                          setIsDisableSaveButton(true);
                        }

                        if (
                          values.lastName &&
                          (values.lastName?.trim().length < 2 ||
                            values.lastName?.trim().length > 20)
                        ) {
                          setErrorMsgLastName(
                            "Must be between 2 and 20 characters"
                          );
                          setIsDisableSaveButton(true);
                        }

                        if (
                          values.lastName?.trim().length >= 2 &&
                          values.lastName?.trim().length <= 20
                        ) {
                          setErrorMsgLastName(undefined);
                        }
                      }}
                      disable={isExternalUser}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <TextInput
                      id="nickName"
                      label="Nickname"
                      onChange={handleChange}
                      defaultValue={userDetail?.nickname}
                      value={values.nickName}
                      errorMessage={errorMsgNickName}
                      onBlur={() => {
                        if (
                          values.nickName &&
                          values.nickName?.trim().length !== 0 &&
                          (values.nickName?.trim().length < 2 ||
                            values.nickName?.trim().length > 20)
                        ) {
                          setErrorMsgNickName(
                            "Must be between 2 and 20 characters"
                          );
                          setIsDisableSaveButton(true);
                        }
                        if (
                          values.nickName &&
                          values.nickName?.trim().length === 0
                        ) {
                          setErrorMsgNickName(undefined);
                        }

                        if (
                          (values.nickName?.trim().length >= 2 &&
                            values.nickName?.trim().length <= 20) ||
                          values.nickName?.trim().length === 0
                        ) {
                          setErrorMsgNickName(undefined);
                        }
                      }}
                      disable={isExternalUser}
                    />
                  </GridItem>
                </Grid>
              </div>

              <div className={styles.filterContainer}>
                {userDetail?.type === Role.LEARNER ? (
                  <Grid>
                    <GridItem width="1/3">
                      <TextInput
                        id="parentFirstName"
                        label="Parent's Firstname"
                        onChange={handleChange}
                        value={values.parentFirstName}
                        errorMessage={errorMsgParentFirstName}
                        disable={isExternalUser}
                        onBlur={() => {
                          if (
                            values.parentFirstName &&
                            (values.parentFirstName?.trim().length < 2 ||
                              values.parentFirstName?.trim().length > 20)
                          ) {
                            // first name: between 2 and 20 characters
                            setErrorMsgParentFirstName(
                              "Must be between 2 and 20 characters"
                            );
                            setIsDisableSaveButton(true);
                          }

                          if (
                            values.parentFirstName?.trim().length >= 2 &&
                            values.parentFirstName?.trim().length <= 20
                          ) {
                            setErrorMsgParentFirstName(undefined);
                          }

                          if (values.parentFirstName?.trim().length === 0) {
                            setErrorMsgParentFirstName(undefined);
                          }
                        }}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <TextInput
                        id="parentLastName"
                        label="Parent's Lastname"
                        onChange={handleChange}
                        value={values.parentLastName}
                        errorMessage={errorMsgParentLastName}
                        disable={isExternalUser}
                        onBlur={() => {
                          if (
                            values.parentLastName &&
                            (values.parentLastName?.trim().length < 2 ||
                              values.parentLastName?.trim().length > 20)
                          ) {
                            setErrorMsgParentLastName(
                              "Must be between 2 and 20 characters"
                            );
                            setIsDisableSaveButton(true);
                          }

                          if (
                            values.parentLastName?.trim().length >= 2 &&
                            values.parentLastName?.trim().length <= 20
                          ) {
                            setErrorMsgParentLastName(undefined);
                          }

                          if (values.parentLastName?.trim().length === 0) {
                            setErrorMsgParentLastName(undefined);
                          }
                        }}
                      />
                    </GridItem>

                    <GridItem width="1/3">
                      <TextInput
                        id="parentEmail"
                        label="Parent's Email"
                        onChange={handleChange}
                        value={values.parentEmail}
                        errorMessage={errorMsgParentEmail}
                        disable={isExternalUser}
                        onBlur={() => {
                          if (
                            values.parentEmail?.trim().length !== 0 &&
                            !validEmailFormat(values.parentEmail?.trim() || "")
                          ) {
                            setErrorMsgParentEmail(
                              "Please enter a valid email address"
                            );
                            setIsDisableSaveButton(true);
                          }

                          if (
                            values.parentEmail?.trim().length !== 0 &&
                            validEmailFormat(values.parentEmail?.trim() || "")
                          ) {
                            setErrorMsgParentEmail(undefined);
                          }

                          if (values.parentEmail?.trim().length === 0) {
                            setErrorMsgParentEmail(undefined);
                          }
                        }}
                      />
                    </GridItem>
                  </Grid>
                ) : (
                  <Grid>
                    <GridItem width="1/3">
                      <div className={styles.schoolSelectContainer}>
                        <SchoolSelect
                          id="schools"
                          label="School"
                          value={values.schools}
                          onChange={(school) => {
                            setFieldValue("schools", school);
                          }}
                          onBlur={() => {
                            if (
                              !values.schools &&
                              userDetail?.type === Role.INSTRUCTOR
                            ) {
                              setErrorMsgSchool("Required field");
                              setIsDisableSaveButton(true);
                            }
                            if (
                              values.schools &&
                              userDetail?.type === Role.INSTRUCTOR
                            ) {
                              setErrorMsgSchool(undefined);
                            }
                          }}
                          querySchools={querySchools}
                          addManualSchool={isExternalUser}
                          setAddManualSchool={() => {}}
                          isBroadcast={false}
                          errorMessage={errorMsgSchool}
                          locale={
                            userDetail?.country === Country.DE ? "de" : "fr"
                          }
                          disablePlaceholder={true}
                          onlyEnglishLang={true}
                          disableEnterSchoolManually={true}
                        ></SchoolSelect>
                      </div>
                    </GridItem>

                    <GridItem width="1/3">
                      {userDetail?.country === Country.DE ? (
                        <TextInput
                          id="numberOfStudents"
                          label="Number of Students"
                          onChange={handleChange}
                          value={values.numberOfStudents}
                          errorMessage={errorMsgNumberOfStudents}
                          disable={isExternalUser}
                          type="number"
                          onBlur={() => {
                            if (
                              values.numberOfStudents <= 0 ||
                              !values.numberOfStudents
                            ) {
                              setErrorMsgNumberOfStudents(
                                "The value entered is invalid"
                              );
                              setIsDisableSaveButton(true);
                            }

                            if (
                              values.numberOfStudents &&
                              values.numberOfStudents > 0
                            ) {
                              setErrorMsgNumberOfStudents(undefined);
                            }
                          }}
                        />
                      ) : (
                        <TeachingLevelSelectAdmin
                          id="teachingLevel"
                          label="Teaching Level"
                          isLabelWithColor={true}
                          value={values.teachingLevel}
                          errorMessage={errorMsgTeachingLevel}
                          onBlur={() => {
                            if (
                              values.teachingLevel?.length === 0 &&
                              userDetail?.type === Role.INSTRUCTOR &&
                              userDetail.country === Country.FR
                            ) {
                              setIsDisableSaveButton(true);
                              setErrorMsgTeachingLevel("Required field");
                            }

                            if (
                              values.teachingLevel?.length > 0 &&
                              userDetail?.type === Role.INSTRUCTOR &&
                              userDetail.country === Country.FR
                            ) {
                              setErrorMsgTeachingLevel(undefined);
                            }
                          }}
                          disable={isExternalUser}
                        ></TeachingLevelSelectAdmin>
                      )}
                    </GridItem>

                    <GridItem width="1/3">
                      <Select
                        id="salutation"
                        options={salutationOptions(
                          userDetail?.language as SupportedLanguage
                        )}
                        label="Salutation"
                        placeholderLabel=""
                        onChange={handleChange}
                        value={values.salutation}
                        disabled={isExternalUser}
                      />
                    </GridItem>
                  </Grid>
                )}
              </div>

              <div className={styles.filterContainer}>
                {userDetail?.type === Role.LEARNER ? (
                  <Grid>
                    <GridItem width="1/3">
                      <div className={styles.schoolSelectContainer}>
                        <SchoolSelect
                          id="schools"
                          label="School"
                          value={values.schools}
                          onChange={(school) => {
                            setFieldValue("schools", school);
                          }}
                          querySchools={querySchools}
                          addManualSchool={isExternalUser}
                          setAddManualSchool={() => {}}
                          locale={
                            userDetail?.country === Country.DE ? "de" : "fr"
                          }
                          disablePlaceholder={true}
                          onlyEnglishLang={true}
                          disableEnterSchoolManually={true}
                        ></SchoolSelect>
                      </div>
                    </GridItem>
                  </Grid>
                ) : (
                  <Grid>
                    {userDetail?.country === Country.DE &&
                    userDetail?.idProvider !== IdProvider.GAR ? (
                      <>
                        <GridItem width="1/3">
                          <TextInput
                            id="tisMarketingConsent"
                            label="tisMarketingConsent"
                            onChange={handleChange}
                            value={values.tisMarketingConsent}
                            disable
                          />
                        </GridItem>
                        <GridItem width="1/3">
                          <TextInput
                            id="tisProfillingConsent"
                            label="tisProfilingConsent"
                            onChange={handleChange}
                            value={values.tisProfilingConsent}
                            disable
                          />
                        </GridItem>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                )}
              </div>

              {userDetail?.type === Role.INSTRUCTOR &&
              userDetail?.idProvider !== IdProvider.GAR ? (
                <>
                  <p>Teaching Subjects</p>
                  <div className={styles.teacherSubjects}>
                    <InstructorSubjectDataStepAdmin
                      locale={userDetail.language}
                      isShowHeader={false}
                      isShowLabel={false}
                      onChange={handleChange}
                      onBlur={() => {
                        if (
                          values.subjects &&
                          values.subjects?.length === 0 &&
                          userDetail?.type === Role.INSTRUCTOR
                        ) {
                          setErrorMsgSubject("Required field");
                          setIsDisableSaveButton(true);
                        }
                        if (
                          values.subjects &&
                          values.subjects?.length > 0 &&
                          userDetail?.type === Role.INSTRUCTOR
                        ) {
                          setErrorMsgSubject(undefined);
                        }
                      }}
                      disable={isExternalUser}
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              <p>Classroom List</p>
              <div className={styles.tableContainer}>
                <Table
                  columns={columns}
                  rows={rows}
                  onRowsChange={() => {}}
                  sortColumns={[]}
                  onSortColumnsChange={() => {}}
                  rowKeyGetter={() => {}}
                  selectedRows={undefined}
                  onSelectedRowsChange={() => {}}
                  sortable={false}
                />
              </div>

              <div className={styles.btnGroup}>
                <Grid>
                  <GridItem width="2/3" className={styles.groupBtn1}>
                    <Button
                      label="delete"
                      btnStyle="danger"
                      size="small"
                      onClick={() => {
                        setIsShowDeleteUserAccountModal(true);
                      }}
                      disabled={isExternalUser}
                    ></Button>
                    <Button
                      label="send recover email"
                      size="small"
                      onClick={() => {
                        setIsShowSendRecoveryEmailModal(true);
                      }}
                      disabled={isExternalUser}
                    ></Button>
                    <Button
                      label="activate"
                      size="small"
                      onClick={() => {
                        setIsShowActiveUserAccountModal(true);
                      }}
                      disabled={userDetail?.status || isExternalUser}
                    ></Button>
                  </GridItem>

                  <GridItem width="1/3" className={styles.groupBtn2}>
                    <Button
                      label="save"
                      size="small"
                      type="submit"
                      disabled={isDisableSaveButton}
                    ></Button>
                    <Button
                      label="cancel"
                      btnStyle="secondary"
                      size="small"
                      onClick={() => setVisible(false)}
                    ></Button>
                  </GridItem>
                </Grid>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className={styles.licenseDetailContainer}>
        <LicenseDetail
          userDetail={userDetail}
          schoolLicenseList={schoolLicenseList}
          fetchUserDetail={fetchUserDetail}
        />
      </div>

      <Spinner
        type="fullContainer"
        title="Please wait..."
        visible={isShowSpinner}
      />
      <SendRecoveryEmailModal
        isShowing={isShowSendRecoveryEmailModal}
        onConfirm={async () => onSendRecoveryEmail(userDetail?.email as string)}
        onCancel={() => setIsShowSendRecoveryEmailModal(false)}
      ></SendRecoveryEmailModal>
      <SendRecoveryEmailSuccessModal
        isShowing={isShowSendRecoveryEmailSuccessModal}
        onCancel={() => {
          setIsShowSendRecoveryEmailSuccessModal(false);
        }}
      ></SendRecoveryEmailSuccessModal>
    </Modal>
  );
};
