import { useState } from "react";
import { TextInput } from "../TextInput/TextInput";
import { Button } from "../Button/Button";
import { Trans } from "@lingui/react";
import { i18n } from "@lingui/core";
import { useNavigate } from "react-router-dom";
import styles from "./JoinContext.module.scss";
import { useToasts } from "../../provider/toast-provider";
import { Role } from "../../types/Role";
import { getContextInformation, joinContext } from "../../api/user-contexts";
import { ContextInformation } from "../../types/ContextInformation";
import { useAuth } from "../../hooks/use-auth";

export const JoinContext = () => {
  const [joinCode, setJoinCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contextInformation, setContextInformation] =
    useState<ContextInformation>();
  const auth = useAuth();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const userRole = auth.userInfo?.role || null;

  const userId = auth?.userInfo?.userId || "";

  const checkJoinCode = async () => {
    setIsSubmitting(true);
    try {
      const contextInformation = await getContextInformation({
        userId,
        code: joinCode,
      });
      setContextInformation(contextInformation);
    } catch (error: any) {
      addToast(i18n._({ id: error.message }), "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const sendJoinCode = async () => {
    setIsSubmitting(true);
    try {
      const context = await joinContext({
        userId,
        code: joinCode,
      });
      const contextId = context.id;
      // insert centralizedLicense
      /** 
       * S1: Student join class that teachers already have school license
       * 
         S2: Student join class before a teacher with school license join
       * 
       * Check action student join class, => check class has school license or not 
       * ( => how to check class has school license? => Class has one of teacher that he/she has a school license  )
       * ( how to check teacher has school license? => Check teacher belong to school and that school has a license)
       * 
          - If yes, add school license to student user, start date = joining date
          - If no, no action required

          When a teacher join a class, check if teacher has valid school license,
          If yes, add school license record to all students
          start date = teacher class joining date, end date = end date off school license */
      // navigate to class of current teacher
      navigate(`/contexts/details/${contextId}`);
      addToast(
        i18n._({ id: "contexts.join.success", values: { name: context.name } })
      );
    } catch (error: any) {
      addToast(i18n._({ id: error.message }), "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkContext = (
    <div data-testid="joinContextModal">
      <p className={styles.description}>
        <Trans id={`contexts.join.description.${userRole?.toLowerCase()}`} />
      </p>
      <TextInput
        id="joinCode"
        label={i18n._({ id: "contexts.join.code" })}
        name="joinCode"
        data-testid="join-code"
        onChange={(e) => setJoinCode(e.target.value)}
      />
      <Button
        label={i18n._({ id: "contexts.join.next" })}
        onClick={checkJoinCode}
        disabled={isSubmitting || joinCode.length <= 3}
      />
    </div>
  );

  const enterContext = (
    <>
      {userRole === Role.LEARNER && (
        <p className={styles.description}>
          <Trans id={"contexts.join.warning"} />
        </p>
      )}
      <p className={styles.contextTitle}>{contextInformation?.name}</p>
      <ul>
        <p className={styles.listTitle}>
          <Trans id={"contexts.join.instructors"} />
        </p>
        {contextInformation?.users?.map((user) => {
          return <li>{user.nickname}</li>;
        })}
      </ul>
      {/** call function to insert centralizedLicense here */}
      <Button
        label={i18n._({ id: "contexts.join.button" })}
        onClick={sendJoinCode}
        disabled={isSubmitting}
      />
    </>
  );

  return (
    <div className={styles.joinContext}>
      {contextInformation ? enterContext : checkContext}
    </div>
  );
};
